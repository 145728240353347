import React, { FunctionComponent } from "react"
import { Link } from "gatsby"

export const AutoArchive: FunctionComponent = () => {
  return (
    <main>
      <h1>Gmail Auto-Archive</h1>
      <p>
        Sometimes, an email has value, but only for a limited amount of time.
        You would want such emails in your inbox, but not have to manually
        archive them. For example, your local news outlet might send you a daily
        email with their headlines, but you would want it out of your inbox by
        the next day when fresher news has been delivered.
      </p>
      <p>
        This app was created based on my own needs, and I don't expect others might
        need this functionality. I mainly am creating an official Google OAuth2
        app so that I don't have to go through the consent screen every week
        (currently the behavior for apps in test mode).
      </p>
      <Link to="/auto-archive/privacy-policy">Privacy Policy</Link>
    </main>
  )
}

export default AutoArchive
